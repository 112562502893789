<template>
  <b-card-code title="Set AR/VR properties for current Editor">
    <div>
      <b-form class="position-relative" @submit.prevent="onSubmit">
        <b-form-group label="AR" label-cols-lg="2" class="checkbox">
          <b-form-checkbox v-model="arValue">
          </b-form-checkbox>
        </b-form-group>
        <div v-if="arValue">
          <b-form-radio-group
            v-model="selectedMethodRadio"
            class="mb-1"
          >
            <b-form-radio :disabled="!hasLicensePermission('editor-ar', 'google_apple_api')" value="google-apple-api">(Google - Apple) API</b-form-radio>
            <a
              v-if="!hasLicensePermission('editor-ar', 'google_apple_api')"
              href="/pricing"
              target="_blank"
              title="AR for higher license"
            >
              <feather-icon
                class="cursor-pointer mr-3"
                icon="AlertTriangleIcon"
                size="20"
                style="color: #F00"
              />
            </a>

            <b-form-radio :disabled="!hasLicensePermission('editor-ar', 'webxr')" value="webxr">WebXR</b-form-radio>
            <a
              v-if="!hasLicensePermission('editor-ar', 'webxr')"
              href="/pricing"
              target="_blank"
              title="AR for higher license"
            >
              <feather-icon
                class="cursor-pointer"
                icon="AlertTriangleIcon"
                size="20"
                style="color: #F00"
              />
            </a>
            <b-form-radio value="three-ar">Marker AR</b-form-radio>
          </b-form-radio-group>
        </div>
        <b-form-group label="VR" label-cols-lg="2" class="checkbox">
          <b-form-checkbox v-model="vrValue">
          </b-form-checkbox>
        </b-form-group>
        <div class="d-flex justify-content-center">
          <b-button
            ref="submit"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            type="submit"
            :disabled="busyButton"
          >
            Submit
          </b-button>
        </div>

        <b-overlay :show="busy" no-wrap @shown="onShown" @hidden="onHidden">
          <template #overlay>
            <div
              v-if="processing"
              class="text-center p-3 bg-primary text-light rounded"
            >
              <feather-icon icon="UploadCloudIcon" size="20" />
              <div class="mb-2">Preservation...</div>
              <b-progress
                min="1"
                max="5"
                :value="counter"
                variant="success"
                height="6px"
                class="mx-n3"
              />
            </div>
            <div
              v-else
              ref="dialog"
              tabindex="-1"
              role="dialog"
              aria-modal="false"
              aria-labelledby="form-confirm-label"
              class="text-center p-3"
            >
              <b-card-text class="font-weight-bolder">
                Are you sure?
              </b-card-text>
              <div class="d-flex">
                <b-button
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  variant="outline-danger"
                  class="mr-3"
                  @click="onCancel"
                >
                  Cancel
                </b-button>
                <b-button
                  v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                  variant="outline-success"
                  @click="onOK"
                >
                  OK
                </b-button>
              </div>
            </div>
          </template>
        </b-overlay>
      </b-form>
    </div>
  </b-card-code>
</template>

<script>
import BCardCode from "@core/components/b-card-code";
import {
  BButton,
  BOverlay,
  BForm,
  BProgress,
  BFormGroup,
  BInputGroup,
  BFormInput,
  BCardText,
  BFormCheckbox,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { ref } from "@vue/composition-api";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import { errorMessage } from "@/auth/utils";
import store from "@/store";

export default {
  components: {
    BButton,
    BOverlay,
    BForm,
    BProgress,
    BFormGroup,
    BInputGroup,
    BCardCode,
    BFormInput,
    BCardText,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  computed: {
    licensePermissions() {
      return this.$store.getters["app/LICENSE_PERMISSIONS"]
    },
    settings() {
      return this.$store.state.Editor.cameraSetting;
    },
    editor() {
      return this.$store.state.Editor.editor;
    },
  },
  data() {
    return {
      editorIsLoaded: false,
      busy: false,
      busyButton: true,
      processing: false,
      counter: 1,
      interval: null,
      arValue: false,
      selected1: false,
      selected2: false,
      selectedMethodRadio: 'google-apple-api',
      vrValue: false,
    };
  },
  beforeDestroy() {
    this.clearInterval();
  },
  setup() {
    const projectData = ref(null);

    store
      .dispatch("Project/loadProject", store.state.Project.project.id)
      .then((response) => {
        projectData.value = response.data;
      })
      .catch((error) => {
        errorMessage(this.$toast, error);
        if (error.response.status === 404) {
          projectData.value = undefined;
        }
      });

    return {
      projectData,
    };
  },
  methods: {
    hasLicensePermission(permission_type, permission_field) {
      // Look in license_permission.json to manage permission_type and permission_field
      try {
        return this.isLicenseChosen(this.licensePermissions[permission_type][permission_field].split(" "))
      } catch {
        return true
      }
    },
    isLicenseChosen(licenseList) {
      for (let license of licenseList) {
        if (license == this.projectData.project_license_type) {
          return true
        }
      }
      return false
    },
    clearInterval() {
      if (this.interval) {
        clearInterval(this.interval);
        this.interval = null;
      }
    },
    onShown() {
      // Focus the dialog prompt
      this.$refs.dialog.focus();
    },
    onHidden() {
      // In this case, we return focus to the submit button
      // You may need to alter this based on your application requirements
      this.$refs.submit.focus();
    },
    onSubmit() {
      if (this.editorIsLoaded) {
        this.processing = false;
        this.busy = true
        this.busyButton = true
      }
    },
    onCancel() {
      this.busy = false;
      this.busyButton = false;
    },

    updateProcessing() {
      // Counter is going up from 1 to 5
      // Simulate an async request
      this.clearInterval();
      this.interval = setInterval(() => {
        if (this.counter < 5) {
          this.counter += 1;
        } else {
          this.clearInterval();
          this.$nextTick(() => {
            // eslint-disable-next-line
            this.busy = this.processing = false;
          });
        }
      }, 350);
    },

    async onOK() {
      this.processing = true;
      this.$store.commit('app/SET_IS_PROJECT_UPDATING', true)
      this.counter = 1;
      const bgSettings = this.settings;

      // If use AR
      if (this.arValue) {
        bgSettings.ar = this.arValue;
        bgSettings.ar_method = this.selectedMethodRadio;
      } else {
        bgSettings.ar = this.arValue;
        delete bgSettings.ar_method;
      }
      
      await this.setBackgroundSettings(bgSettings);
      this.updateProcessing();
      this.$store.commit('app/SET_IS_PROJECT_UPDATING', false)
      await this.loadEditorAsync();
    },

    async setBackgroundSettings(bgSettings) {
      // PATCH Editor with new settings_json file
      await this.$store.commit("Editor/SET_CAMERA", bgSettings);
      await this.$store
        .dispatch("Editor/changeSetting", this.$store.state.Editor.editor.id)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title:
                "You have successfully update Background/Environment general setting",
              icon: "EditIcon",
              variant: "success",
            },
          });
        })
        .catch((error) => {
          errorMessage(this.$toast, error);
        });
    },

    async loadEditorAsync() {
      const _this = this
      // Set headers for fetch
      let myHeaders = new Headers();
      myHeaders.append('pragma', 'no-cache');
      myHeaders.append('cache-control', 'no-cache');

      // Set request options for fetch
      const myInit = {
        method: 'GET',
        headers: myHeaders,
      };

      await this.$store
        .dispatch("Editor/getEditor", this.$store.state.Editor.editor.id)
        .then((res) => {
          this.settings_json_s3_path = res.data.settings_json_s3_path;
        
        this.$store.commit("Editor/SET_EDITOR", res.data);
        this.$store
          .dispatch("Editor/setCameraSetting") // Fetch settings json file
          .then((response) => response.json())
          .then((data) => {
            this.$store.commit("Editor/SET_CAMERA", data);
          });
      })
        .catch((error) => {
          errorMessage(this.$toast, error);
        });

      // Create settings_json_data with initial settings
      let settings_json_data = {arValue: this.arValue, selectedMethodRadio: this.selectedMethodRadio}

      // Get data by settings_json_s3_path PATH and myInit request options
      await fetch(this.settings_json_s3_path, myInit)
        .then(function (response) {
          return response.json() // Get json data first
        }).then(function(response) {
          if (response.ar) {
            settings_json_data.arValue = response.ar
          }
          if (response.ar_method) {
            settings_json_data.selectedMethodRadio = response.ar_method
          }
          _this.editorIsLoaded = true
          _this.busyButton = false
      })

      // Initialize settings data here, cz we can not change 'this' data inside 'fetch' function
      this.arValue = settings_json_data.arValue
      this.selectedMethodRadio = settings_json_data.selectedMethodRadio
    },
  },

  mounted() {
    this.loadEditorAsync();
  },
};
</script>

<style lang="scss">

</style>
